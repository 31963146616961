import { FC, lazy, memo, Suspense, useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';

import { CognitoGroup } from 'src/apollo';
import { useAuth } from 'src/auth';
import { FullLoading } from 'src/components/layout';
import SateliteEdit from 'src/components/pages/SateliteEdit';
import { Status } from 'src/enums';
import { PageTitle } from 'src/utils';
import { initCompany, useCompany, useStatus } from 'src/hooks';
import { Routes as AppRoutes } from './routes';
import { RequireGroup } from './RequireGroup/RequireGroup';
import { TrackPage } from './TrackPage/TrackPage';

const CompanyAddIFA = lazy(() => import('src/components/pages/CompanyAddIFA'));
const HistoricReturns = lazy(
  () => import('src/components/pages/HistoricReturns'),
);
const Factsheets = lazy(() => import('src/components/pages/Factsheets'));
const FactsheetEdit = lazy(() => import('src/components/pages/FactsheetEdit'));
const Advisers = lazy(() => import('src/components/pages/Advisers'));
const CompanyAdvisers = lazy(
  () => import('src/components/pages/CompanyAdvisers'),
);
const CompanyPage = lazy(() => import('src/components/pages/CompanyPage'));
const CompanyList = lazy(() => import('src/components/pages/CompanyList'));
const Customers = lazy(() => import('src/components/pages/Customers'));
const Dashboard = lazy(() => import('src/components/pages/Dashboard'));
const AddIfaPage = lazy(() => import('src/components/pages/Ifa/AddIfaPage'));
const EditIfaPage = lazy(() => import('src/components/pages/Ifa/EditIfaPage'));
const IndexPage = lazy(() => import('src/components/pages/IndexPage'));
const JourneyPage = lazy(() => import('src/components/pages/JourneyPage'));
const LoginPage = lazy(() => import('src/components/pages/LoginPage'));
const LogoutPage = lazy(() => import('src/components/pages/LogoutPage'));
const UsefulResourcesPage = lazy(
  () => import('src/components/pages/UsefulResourcesPage'),
);
const QuestionnairePage = lazy(
  () => import('src/components/pages/Questionnaire'),
);
const ResetPasswordPage = lazy(
  () => import('src/components/pages/ResetPasswordPage'),
);
const SatellitesPage = lazy(
  () => import('src/components/pages/SatellitesPage'),
);
const MarketUpdatesPage = lazy(
  () => import('src/components/pages/MarketUpdatesPage'),
);
const MarketUpdatePage = lazy(
  () =>
    import(
      'src/components/pages/MarketUpdatesPage/MarketUpdatesAdmin/AddMarketUpdate/MonthlyMarketUpdateForm'
    ),
);
const MarketPulseUpdatePage = lazy(
  () =>
    import(
      'src/components/pages/MarketUpdatesPage/MarketUpdatesAdmin/AddMarketUpdate/MarketPulseUpdateForm'
    ),
);
const MarketInsightsUpdatePage = lazy(
  () =>
    import(
      'src/components/pages/MarketUpdatesPage/MarketUpdatesAdmin/AddMarketUpdate/MarketInsightsUpdateForm'
    ),
);
const RiskProfilePage = lazy(() => import('src/components/pages/RiskProfile'));
const NotificationsListPage = lazy(
  () => import('src/components/pages/NotificationsList'),
);
const AddNotificationPage = lazy(
  () => import('src/components/pages/AddNotificationPage'),
);
const EditNotificationPage = lazy(
  () => import('src/components/pages/EditNotificationPage'),
);
const NotFoundPage = lazy(() => import('src/components/pages/NotFoundPage'));
const PortfolioPositioningPage = lazy(
  () => import('src/components/pages/PortfolioPositioning'),
);
const PortfolioPositioningEdit = lazy(
  () => import('src/components/pages/PortfolioPositioningEdit'),
);

export const Routing: FC = memo(() => {
  const { init } = useAuth();
  const ifaCompany = useCompany();
  const { state, execute } = useStatus();
  const { state: companyState, execute: companyExecute } = useStatus();
  useEffect(() => execute(init()), [execute, init]);
  useEffect(() => {
    // wait for auth
    if (state !== Status.Success) return;
    companyExecute(initCompany());
  }, [companyExecute, state]);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path={AppRoutes.Index} element={<IndexPage />} />
        <Route path={AppRoutes.Login} element={<LoginPage />} />
        <Route path={AppRoutes.Logout} element={<LogoutPage />} />
        <Route path={AppRoutes.ResetPassword} element={<ResetPasswordPage />} />
        <Route
          path={AppRoutes.Dashboard}
          element={
            <RequireGroup>
              <Dashboard />
            </RequireGroup>
          }
        />
        <Route
          path={AppRoutes.Advisers}
          element={
            <RequireGroup groups={[CognitoGroup.IfaCompanyAdmin]}>
              <TrackPage title={PageTitle.AdvisersList}>
                <Advisers />
              </TrackPage>
            </RequireGroup>
          }
        />
        <Route
          path={AppRoutes.Companies}
          element={
            <RequireGroup groups={[CognitoGroup.MasterAdmin]}>
              <TrackPage title={PageTitle.CompaniesList}>
                <CompanyList />
              </TrackPage>
            </RequireGroup>
          }
        />
        <Route
          path={`${AppRoutes.Company}/:id?`}
          element={
            <RequireGroup groups={[CognitoGroup.MasterAdmin]}>
              {/* TODO: Once IFA Admin migration is merged */}
              {/* Attach IFA Company name to page title */}
              <TrackPage title={PageTitle.CompanyForm}>
                <CompanyPage />
              </TrackPage>
            </RequireGroup>
          }
        />
        <Route
          path={AppRoutes.CompanyDemo}
          element={
            <RequireGroup groups={[CognitoGroup.MasterAdmin]}>
              <TrackPage title={PageTitle.CompanyFormDemo}>
                <CompanyPage demoMode />
              </TrackPage>
            </RequireGroup>
          }
        />
        <Route
          path={AppRoutes.HistoricReturns}
          element={
            <RequireGroup groups={[CognitoGroup.MasterAdmin]}>
              <TrackPage title={PageTitle.HistoricReturns}>
                <HistoricReturns />
              </TrackPage>
            </RequireGroup>
          }
        />
        <Route
          path={AppRoutes.Factsheets}
          element={
            <RequireGroup>
              <Factsheets />
            </RequireGroup>
          }
        />
        <Route
          path={`${AppRoutes.Factsheets}/:id`}
          element={
            <RequireGroup
              groups={[CognitoGroup.MasterAdmin, CognitoGroup.IfaCompanyAdmin]}
            >
              <TrackPage title={PageTitle.FactsheetEdit}>
                <FactsheetEdit />
              </TrackPage>
            </RequireGroup>
          }
        />
        <Route
          path={`${AppRoutes.Satellites}/:id`}
          element={
            <RequireGroup
              groups={[CognitoGroup.MasterAdmin, CognitoGroup.IfaCompanyAdmin]}
            >
              <TrackPage title={PageTitle.SatelliteEdit}>
                <SateliteEdit />
              </TrackPage>
            </RequireGroup>
          }
        />
        <Route
          path={`${AppRoutes.Company}/:id/advisers`}
          element={
            <RequireGroup groups={[CognitoGroup.MasterAdmin]}>
              <TrackPage title={PageTitle.IfaAdminsList}>
                <CompanyAdvisers />
              </TrackPage>
            </RequireGroup>
          }
        />
        <Route
          path={`${AppRoutes.Company}/:id/add`}
          element={
            <RequireGroup
              groups={[CognitoGroup.MasterAdmin, CognitoGroup.IfaCompanyAdmin]}
            >
              <TrackPage title={PageTitle.AddIfaForCompany}>
                <CompanyAddIFA />
              </TrackPage>
            </RequireGroup>
          }
        />
        <Route
          path={AppRoutes.Customers}
          element={
            <RequireGroup groups={[CognitoGroup.Ifa]}>
              <Customers />
            </RequireGroup>
          }
        />

        <Route
          path={`${AppRoutes.Customers}/:userId`}
          element={
            <RequireGroup groups={[CognitoGroup.IfaCompanyAdmin]}>
              <Customers />
            </RequireGroup>
          }
        />
        <Route
          path={AppRoutes.Ifa}
          element={
            <RequireGroup groups={[CognitoGroup.IfaCompanyAdmin]}>
              <TrackPage title={PageTitle.AddIfaPage}>
                <AddIfaPage />
              </TrackPage>
            </RequireGroup>
          }
        />
        <Route
          path={`${AppRoutes.Ifa}/:id`}
          element={
            <RequireGroup groups={[CognitoGroup.IfaCompanyAdmin]}>
              <EditIfaPage />
            </RequireGroup>
          }
        />
        <Route
          path={`${AppRoutes.Journey}/:id?/:step?`}
          element={
            <RequireGroup groups={[CognitoGroup.Ifa]}>
              <JourneyPage />
            </RequireGroup>
          }
        />
        <Route
          path={`${AppRoutes.ShortJourney}/:id?/:step?`}
          element={
            <RequireGroup groups={[CognitoGroup.Ifa]}>
              <JourneyPage />
            </RequireGroup>
          }
        />
        <Route
          path={`${AppRoutes.RiskProfile}/:id?/:step?`}
          element={
            <RequireGroup groups={[CognitoGroup.Ifa]}>
              <JourneyPage />
            </RequireGroup>
          }
        />
        <Route
          path={`${AppRoutes.Journey}/:id/:step/:userId`}
          element={
            <RequireGroup groups={[CognitoGroup.IfaCompanyAdmin]}>
              <JourneyPage />
            </RequireGroup>
          }
        />
        <Route
          path={`${AppRoutes.ShortJourney}/:id/:step/:userId`}
          element={
            <RequireGroup groups={[CognitoGroup.IfaCompanyAdmin]}>
              <JourneyPage />
            </RequireGroup>
          }
        />
        <Route
          path={`${AppRoutes.RiskProfile}/:id/:step/:userId`}
          element={
            <RequireGroup groups={[CognitoGroup.IfaCompanyAdmin]}>
              <JourneyPage />
            </RequireGroup>
          }
        />
        <Route
          path={AppRoutes.Satellites}
          element={
            <RequireGroup>
              <TrackPage title={PageTitle.Satellites}>
                <SatellitesPage />
              </TrackPage>
            </RequireGroup>
          }
        />
        <Route
          path={`${AppRoutes.Satellites}/:id/view`}
          element={
            <RequireGroup>
              <TrackPage title={PageTitle.Satellites}>
                <SatellitesPage />
              </TrackPage>
            </RequireGroup>
          }
        />
        <Route
          path={AppRoutes.MarketUpdates}
          element={
            <RequireGroup>
              <MarketUpdatesPage />
            </RequireGroup>
          }
        />
        <Route
          path={AppRoutes.InvestorRiskProfile}
          element={
            <RequireGroup groups={[CognitoGroup.Ifa]}>
              <TrackPage title={PageTitle.RiskProfile}>
                <RiskProfilePage />
              </TrackPage>
            </RequireGroup>
          }
        />
        <Route
          path={`${AppRoutes.Questionnaire}/:id`}
          element={
            <TrackPage title={PageTitle.QuestionnaireForm}>
              <QuestionnairePage />
            </TrackPage>
          }
        />
        <Route
          path={`${AppRoutes.MarketUpdate}/monthly`}
          element={
            <RequireGroup groups={[CognitoGroup.MasterAdmin]}>
              <TrackPage title={PageTitle.MonthlyMarketUpdate}>
                <MarketUpdatePage />
              </TrackPage>
            </RequireGroup>
          }
        />
        <Route
          path={`${AppRoutes.MarketUpdate}/pulse`}
          element={
            <RequireGroup groups={[CognitoGroup.MasterAdmin]}>
              <TrackPage title={PageTitle.PulseMarketUpdate}>
                <MarketPulseUpdatePage />
              </TrackPage>
            </RequireGroup>
          }
        />
        <Route
          path={`${AppRoutes.MarketUpdate}/insights`}
          element={
            <RequireGroup groups={[CognitoGroup.MasterAdmin]}>
              <TrackPage title={PageTitle.InsightsMarketUpdate}>
                <MarketInsightsUpdatePage />
              </TrackPage>
            </RequireGroup>
          }
        />
        <Route
          path={`${AppRoutes.MarketUpdate}/monthly/:id`}
          element={
            <RequireGroup groups={[CognitoGroup.MasterAdmin]}>
              <TrackPage title={PageTitle.MonthlyMarketUpdate}>
                <MarketUpdatePage />
              </TrackPage>
            </RequireGroup>
          }
        />
        <Route
          path={`${AppRoutes.MarketUpdate}/pulse/:id`}
          element={
            <RequireGroup groups={[CognitoGroup.MasterAdmin]}>
              <TrackPage title={PageTitle.PulseMarketUpdate}>
                <MarketPulseUpdatePage />
              </TrackPage>
            </RequireGroup>
          }
        />
        <Route
          path={`${AppRoutes.MarketUpdate}/insights/:id`}
          element={
            <RequireGroup groups={[CognitoGroup.MasterAdmin]}>
              <TrackPage title={PageTitle.InsightsMarketUpdate}>
                <MarketInsightsUpdatePage />
              </TrackPage>
            </RequireGroup>
          }
        />
        <Route
          path={AppRoutes.UsefulResources}
          element={
            <RequireGroup>
              <UsefulResourcesPage />
            </RequireGroup>
          }
        />
        <Route
          path={AppRoutes.NotificationsList}
          element={
            <RequireGroup groups={[CognitoGroup.MasterAdmin]}>
              <NotificationsListPage />
            </RequireGroup>
          }
        />
        <Route
          path={AppRoutes.CreateNotification}
          element={
            <RequireGroup groups={[CognitoGroup.MasterAdmin]}>
              <AddNotificationPage />
            </RequireGroup>
          }
        />
        <Route
          path={`${AppRoutes.EditNotification}/:id`}
          element={
            <RequireGroup groups={[CognitoGroup.MasterAdmin]}>
              <EditNotificationPage />
            </RequireGroup>
          }
        />
        <Route
          path={AppRoutes.PortfolioPositioning}
          element={
            <RequireGroup>
              <PortfolioPositioningPage />
            </RequireGroup>
          }
        />
        <Route
          path={`${AppRoutes.PortfolioPositioning}/edit`}
          element={
            <RequireGroup groups={[CognitoGroup.MasterAdmin]}>
              <PortfolioPositioningEdit />
            </RequireGroup>
          }
        />
        <Route
          path="*"
          element={
            <RequireGroup>
              <NotFoundPage />
            </RequireGroup>
          }
        />
      </>,
    ),
  );

  if (!ifaCompany && companyState !== Status.Success) {
    return <FullLoading />;
  }

  if (state !== Status.Success) {
    return <FullLoading />;
  }
  return (
    <Suspense fallback={<FullLoading />}>
      <RouterProvider router={router} />
    </Suspense>
  );
});
