/* eslint-disable react/jsx-props-no-spreading */
import { Dispatch, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, lighten, Typography, useTheme } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import {
  DragDropContext,
  DropResult,
  DroppableProvided,
} from 'react-beautiful-dnd';

import { NumberInput, TrashIcon, Textarea, AddIcon } from 'src/components/ui';
import { parseInputNumber } from 'src/utils';

import { StrictDroppable } from 'src/components/shared';
import { DragableRow } from 'src/components/pages/FactsheetEdit/SecondPage/FundSection/DragableRow';
import { useStyles } from './styles';
import { Disclaimer, Exposures, Funds } from '../assets';
import { FactsheetSection } from '../FactsheetSection';
import { State } from '../interfaces';
import { maxCumulativeOverview, maxExposures, maxFunds } from '../config';

const maxRiskWarningsLength = 2100;

interface Exposure {
  name: string;
  color: number;
  share: string;
}

export interface Fund {
  key: string;
  order: number;
  name: string;
  type: string;
  color: number;
  allocation: string;
  overview: string;
}

interface ExposureNode extends Exposure {
  key: string;
}

interface FundNode extends Fund {
  expKey?: string;
}

interface ExposureEntryProps {
  onDelete?: () => void;
  exposures: ExposureNode[];
  idx: number;
  dispatch: Dispatch<Partial<State>>;
}

const ExposureEntry: FC<ExposureEntryProps> = ({
  onDelete,
  dispatch,
  exposures,
  idx,
}) => {
  const { classes } = useStyles();
  const exposure = exposures[idx] as ExposureNode;
  return (
    <Box className={classes.exposureEntry}>
      <Box style={{ width: '45%' }}>
        <input
          defaultValue={exposure.name}
          onChange={(e) => {
            const newExposures = [...exposures];
            newExposures[idx] = { ...exposure, name: e.target.value };
            dispatch({ chosenExposures: newExposures });
          }}
        />
      </Box>
      <Box style={{ width: '40%' }} className="color-wrap">
        {[...Array(6)].map((_, i) => {
          return (
            <Box
              key={`dot-${exposure.color + i}`}
              className={`dot${exposure.color === i ? ' selected' : ''}`}
              onClick={() => {
                const newExposures = [...exposures];
                newExposures[idx] = { ...exposure, color: i };
                dispatch({ chosenExposures: newExposures });
              }}
            />
          );
        })}
      </Box>
      <Box style={{ width: '15%', display: 'flex', alignItems: 'center' }}>
        <Box style={{ display: 'flex', gap: 8 }}>
          <NumberInput
            value={exposure.share}
            charLimit={10}
            onChange={(value) => {
              const newExposures = [...exposures];
              newExposures[idx] = { ...exposure, share: value.toString() };
              dispatch({ chosenExposures: newExposures });
            }}
            percentFormat
          />
          <Box style={{ display: 'inherit' }} onClick={onDelete}>
            <TrashIcon className={classes.deleteIcon} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

interface Props {
  values: State;
  dispatch: Dispatch<Partial<State>>;
}

export const SecondPage: FC<Props> = ({ values, dispatch }) => {
  const { classes, cx } = useStyles();
  const theme = useTheme();

  const colorsLut = [
    theme.palette.primary.main,
    lighten(theme.palette.primary.main, 0.4),
    lighten(theme.palette.primary.main, 0.7),
    '#727272',
    '#AAAAAA',
    '#D5D5D5',
  ];

  const [fundEntries, setFundEntries] = useState<FundNode[]>([]);
  const [chosenExposures, setChosenExposures] = useState<ExposureNode[]>([]);

  useEffect(() => {
    if (!values.chosenExposures) return;
    const initialExposures = values.chosenExposures.map((exp, i) => {
      return {
        key: `exp${i}`,
        name: exp.name ?? '',
        share: exp.share ?? '',
        color: exp.color ?? -1,
      };
    });
    setChosenExposures(initialExposures);
  }, [values.chosenExposures]);

  useEffect(() => {
    if (!values.fundEntries) return;
    const initialFunds = values.fundEntries.map((fund, i) => {
      return {
        key: fund.key ?? '',
        order: fund.order ?? i,
        name: fund.name ?? '',
        type: fund.type ?? '',
        color: fund.color ?? -1,
        allocation: fund.allocation ?? '',
        overview: fund.overview ?? '',
      };
    });
    setFundEntries(initialFunds);
  }, [values.fundEntries]);

  const totalAllocation = fundEntries
    .map(({ allocation }) => parseInputNumber(allocation)[1] ?? 0)
    .reduce((a, b) => a + b, 0);

  const totalOverview = fundEntries
    .map(({ overview }) => overview.length ?? 0)
    .reduce((a, b) => a + b, 0);

  const totalShares = chosenExposures
    .map(({ share }) => parseInputNumber(share)[1] ?? 0)
    .reduce((a, b) => a + b, 0);

  const printSummary = useMemo(() => {
    return (
      <div className={classes.summary}>
        <Typography variant="h3">
          Funds {fundEntries.length}/{maxFunds}
        </Typography>
        <Typography variant="h3">
          Total overview{' '}
          <span
            className={cx({
              [classes.error]: totalOverview > maxCumulativeOverview,
            })}
          >
            {totalOverview}/{maxCumulativeOverview}
          </span>
        </Typography>
        <Typography variant="h3">
          Total allocation{' '}
          <span
            className={cx({
              [classes.error]: totalAllocation !== 100,
            })}
          >
            {totalAllocation.toFixed(2)}%
          </span>
        </Typography>
      </div>
    );
  }, [classes, fundEntries, totalAllocation, totalOverview, cx]);

  const handleOnDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination || !fundEntries) return;
      const i = Array.from(fundEntries);
      const [reorderedItem] = i.splice(result.source.index, 1);
      if (reorderedItem) {
        const sourceIndex = result?.source?.index ?? 0;
        const destinationIndex = result?.destination?.index ?? 0;
        const mappedItems = i.map((item) => {
          if (
            sourceIndex < destinationIndex &&
            item.order >= sourceIndex + 1 &&
            item.order <= destinationIndex + 1
          ) {
            return { ...item, order: item.order - 1 };
          }
          if (
            sourceIndex > destinationIndex &&
            item.order <= sourceIndex + 1 &&
            item.order >= destinationIndex + 1
          ) {
            return { ...item, order: item.order + 1 };
          }
          return item;
        });

        mappedItems.splice(result.destination.index, 0, {
          ...reorderedItem,
          order: result.destination.index + 1,
        });
        const updatedData = { ...values, fundEntries: mappedItems };
        setFundEntries(mappedItems);
        dispatch(updatedData);
      }
    },
    [dispatch, fundEntries, values],
  );

  const handleAddNewItem = () => {
    if (fundEntries.length >= maxFunds) return;
    const newFundEntries = [...(values.fundEntries ?? [])];
    newFundEntries.push({
      key: uuidv4(),
      order: newFundEntries.length,
      name: '',
      type: '',
      color: -1,
      allocation: '',
      overview: '',
    });
    setFundEntries(newFundEntries);
    dispatch({ ...values, fundEntries: newFundEntries });
  };

  return (
    <div>
      <FactsheetSection image={<Exposures />} label="Portfolio Exposures">
        <Box className={classes.exposuresWrap}>
          <Box className={classes.exposuresHeader}>
            <Box style={{ width: '45%' }}>Name</Box>
            <Box style={{ width: '40%', textAlign: 'center' }}>Colour</Box>
            <Box style={{ width: '15%' }}>Share</Box>
          </Box>
          {chosenExposures.map((entry, i) => {
            return (
              <ExposureEntry
                key={entry.key}
                exposures={chosenExposures}
                idx={i}
                dispatch={dispatch}
                onDelete={() => {
                  const newExposures = [...(values.chosenExposures ?? [])];
                  newExposures.splice(i, 1);
                  dispatch({ chosenExposures: newExposures });
                }}
              />
            );
          })}
        </Box>
        <Box className={classes.buttonWrapper}>
          <Button
            variant="outlined"
            disabled={chosenExposures.length >= maxExposures}
            onClick={() => {
              if (chosenExposures.length >= maxExposures) return;
              const newChosenExposures = [...(values.chosenExposures ?? [])];
              newChosenExposures.push({
                name: '',
                color: -1,
                share: '',
              });
              dispatch({ chosenExposures: newChosenExposures });
            }}
            className={classes.addItem}
            startIcon={<AddIcon />}
          >
            Add Pie
          </Button>
          <Typography className={classes.entriesCounter}>
            {chosenExposures.length} out of {maxExposures}, total{' '}
            <span
              className={cx({
                [classes.error]: totalShares !== 100,
              })}
            >
              {totalShares.toFixed(2)}%
            </span>
          </Typography>
        </Box>
      </FactsheetSection>
      <FactsheetSection image={<Funds />}>
        <Typography variant="body2">
          Enter up to {maxFunds} Fund Names.
        </Typography>
        {printSummary}
        <Box className={classes.fundsWrap}>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <StrictDroppable droppableId="funds">
              {(provided: DroppableProvided) => (
                <ul {...provided.droppableProps} ref={provided.innerRef}>
                  {fundEntries?.map((entry, i) => {
                    return (
                      <DragableRow key={entry.key} id={entry.key} index={i}>
                        <Box className={classes.fundEntry}>
                          <Box className={classes.fundInputs}>
                            <Box className={classes.fundLabels}>
                              <Box>Fund Name</Box>
                              <Box>Allocation</Box>
                            </Box>
                            <Box className={classes.fundInfo}>
                              <input
                                defaultValue={entry.name}
                                onChange={(ev) => {
                                  const newFundEntries = [
                                    ...(values.fundEntries ?? []),
                                  ];
                                  newFundEntries[i] = {
                                    ...newFundEntries[i],
                                    name: ev.target.value,
                                  } as FundNode;
                                  dispatch({ fundEntries: newFundEntries });
                                }}
                                style={{ width: '60%' }}
                                maxLength={40}
                              />
                              <Box
                                style={{ width: '25%', position: 'relative' }}
                              >
                                <Box
                                  className={classes.selectionDot}
                                  style={{
                                    backgroundColor:
                                      colorsLut[entry?.color ?? -1] ?? 'black',
                                  }}
                                />
                                <select
                                  onChange={(e) => {
                                    const [name, color] = JSON.parse(
                                      e.target.value,
                                    );
                                    const newFundEntries = [
                                      ...(values.fundEntries ?? []),
                                    ];
                                    newFundEntries[i] = {
                                      ...newFundEntries[i],
                                      type: name,
                                      color,
                                    } as FundNode;
                                    dispatch({ fundEntries: newFundEntries });
                                  }}
                                  defaultValue={JSON.stringify([
                                    entry.type,
                                    entry.color,
                                  ])}
                                >
                                  <option value={JSON.stringify(['', -1])}>
                                    -
                                  </option>
                                  {chosenExposures.map((e) => {
                                    return (
                                      <option
                                        key={e.key}
                                        value={JSON.stringify([
                                          e.name,
                                          e.color,
                                        ])}
                                      >
                                        {e.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </Box>
                              <NumberInput
                                value={entry.allocation}
                                charLimit={10}
                                onChange={(value) => {
                                  const newFundEntries = [
                                    ...(values.fundEntries ?? []),
                                  ];
                                  newFundEntries[i] = {
                                    ...newFundEntries[i],
                                    allocation: value.toString(),
                                  } as FundNode;
                                  dispatch({ fundEntries: newFundEntries });
                                }}
                                percentFormat
                              />
                            </Box>
                            <Box className={classes.fundLabels}>
                              <Box>Overview</Box>
                            </Box>
                            <textarea
                              defaultValue={entry.overview}
                              maxLength={maxCumulativeOverview}
                              onChange={(ev) => {
                                const newFundEntries = [
                                  ...(values.fundEntries ?? []),
                                ];
                                newFundEntries[i] = {
                                  ...newFundEntries[i],
                                  overview: ev.target.value,
                                } as FundNode;
                                dispatch({ fundEntries: newFundEntries });
                              }}
                            />
                          </Box>
                          <Box
                            className={classes.fundDeleteWrap}
                            onClick={() => {
                              const newFundEntries = [
                                ...(values.fundEntries ?? []),
                              ];
                              newFundEntries.splice(i, 1);
                              dispatch({ fundEntries: newFundEntries });
                            }}
                          >
                            <TrashIcon className={classes.deleteIcon} />
                          </Box>
                        </Box>
                      </DragableRow>
                    );
                  })}
                </ul>
              )}
            </StrictDroppable>
          </DragDropContext>
        </Box>
        <Button
          variant="outlined"
          disabled={fundEntries.length >= maxFunds}
          onClick={handleAddNewItem}
          className={classes.addItem}
          startIcon={<AddIcon />}
          style={{ marginTop: 20 }}
        >
          Add New Fund
        </Button>
      </FactsheetSection>
      <FactsheetSection image={<Disclaimer />}>
        <Textarea
          name="riskWarnings"
          value={values.riskWarnings ?? ''}
          label="Disclaimer"
          rows={30}
          maxCharLength={maxRiskWarningsLength}
          charLimit={maxRiskWarningsLength}
          tip="21 lines"
          onChange={(ev) => dispatch({ riskWarnings: ev.target.value })}
          error={(values.riskWarnings?.length ?? 0) > maxRiskWarningsLength}
          helperText={
            (values.riskWarnings?.length ?? 0) > maxRiskWarningsLength
              ? 'Too many characters'
              : ''
          }
        />
      </FactsheetSection>
    </div>
  );
};
