export type Paginated<T> = {
  data: T[];
  page: number;
  pageSize: number;
  pageCount: number;
};

export type SearchQueryArg = {
  search?: string;
};

export type PageQueryArg = {
  page?: number;
  pageSize?: number;
};

export type OrderQueryArg = {
  reverse?: boolean;
};

export type SortQueryArg<T> = {
  sortBy?: T;
};

export type ListQueryArgs<T> = SearchQueryArg &
  PageQueryArg &
  OrderQueryArg &
  SortQueryArg<T>;

export const enum QueryStatus {
  Success,
  Error,
  Pending,
}

export type RestQuery<T> =
  | {
      status: QueryStatus.Success;
      data: T;
      reFetch: () => Promise<void>;
    }
  | {
      status: QueryStatus.Error;
      data: undefined;
      error: Error;
      reFetch: () => Promise<void>;
    }
  | {
      status: QueryStatus.Pending;
      data: undefined;
      reFetch: () => Promise<void>;
    };
