import { FC, useEffect, useReducer, useState } from 'react';
import { Button } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { FullLoading, FullPage, Wrapper } from 'src/components/layout';
import { Header } from 'src/components/shared';
import { Routes } from 'src/routing';

import { useDialog, useSnack } from 'src/hooks';
import { getSateliteById, updateSatellite } from 'src/restApi';
import { useLocation, useParams } from 'react-router-dom';
import { parseInputNumber } from 'src/utils';
import { confirmModal } from 'src/utils/confirmModal';
import Prompt from 'src/components/shared/Prompt/Prompt';
import { FirstPage } from './FirstPage';
import { State } from './interfaces';
import { SecondPage } from './SecondPage';
import { useStyles } from './styles';
import { parseInitialValues, parseToUpdateInput } from './utils';
import { PREVIOUS_TAB_QUERY_PARAM } from '../const';
import { Satelite } from '../Factsheets/SateliteList/Satelite';
import { maxCumulativeOverview, maxExposures, maxFunds } from './config';

export const SateliteEdit: FC = () => {
  const { classes } = useStyles();
  const snackbar = useSnack();
  const dialog = useDialog();
  const { id } = useParams<{ id: string }>();

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [satellite, setSatellite] = useState<Satelite | null>(null);
  const [hasChanges, setHasChanges] = useState(false);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const currentTab = urlParams.get(PREVIOUS_TAB_QUERY_PARAM);

  useEffect(() => {
    async function fetchDataFromApi() {
      if (!id) return;
      try {
        const fetchedSatellite = (await getSateliteById(id)) as Satelite;
        fetchedSatellite.fundEntries?.forEach((f, i) => {
          const fund = f;
          if (!fund.key) fund.key = uuidv4();
          fund.order = i;
        });
        setSatellite(fetchedSatellite);
        setLoading(false);
        if (fetchedSatellite === null) {
          snackbar.show({
            message: 'Could not find requested satellite portfolio',
          });
        }
      } catch (error) {
        setLoading(false);
        snackbar.show({ message: 'Fetching satellite portfolio failed' });
      }
    }

    fetchDataFromApi();
  }, [snackbar, id]);

  const [values, dispatch] = useReducer(
    (state: State, newState: Partial<State>) => {
      return {
        ...state,
        ...newState,
      };
    },
    {},
  );

  const dispatchWrap = (value: Partial<State>) => {
    snackbar.close();
    setHasChanges(true);
    dispatch(value);
  };

  useEffect(() => {
    if (!satellite) return;
    dispatch(parseInitialValues(satellite));
  }, [satellite]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handler = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      /* eslint-disable no-param-reassign */
      event.returnValue = '';
    };
    if (hasChanges) {
      window.addEventListener('beforeunload', handler);
      return () => {
        window.removeEventListener('beforeunload', handler);
      };
    }
    return () => {};
  }, [hasChanges]);

  const satelliteName = satellite ? ` / ${satellite.name} Portfolio` : '';

  const totalPercent = (percentValues?: (string | undefined)[]) => {
    if (!percentValues) return 0;
    return percentValues
      .map((v) => parseInputNumber(v ?? '0')[1] ?? 0)
      .reduce((a, b) => a + b, 0);
  };

  const validateSatellite = () => {
    const errors = [];
    if (Number(values.chosenExposures?.length) > maxExposures) {
      errors.push(`Cannot have more than ${maxExposures} exposures.`);
    }
    if (Number(values.fundEntries?.length) > maxFunds) {
      errors.push(`Cannot have more than ${maxFunds} funds.`);
    }
    const allShares = values.chosenExposures?.map((e) => e.share);
    const allFunds = values.fundEntries?.map((e) => e.allocation);
    const totalShare = totalPercent(allShares);
    const totalAllocation = totalPercent(allFunds);
    if (allShares?.some((s) => Number.isNaN(parseFloat(`${s}`))) === true) {
      errors.push('All exposures must have a share.');
    }
    if (allFunds?.some((f) => Number.isNaN(parseFloat(`${f}`))) === true) {
      errors.push('All funds must have an allocation.');
    }
    if (totalShare !== 100) {
      errors.push('Total share must equal 100%.');
    }
    if (totalAllocation !== 100) {
      errors.push('Total allocation must equal 100%.');
    }

    if (
      values.chosenExposures?.some(
        (e) => e.color === undefined || e.color < 0,
      ) === true
    ) {
      errors.push('All exposures must have a colour.');
    }
    if (values.chosenExposures?.some((e) => !e.name) === true) {
      errors.push('All exposures must have a name.');
    }
    if (values.fundEntries?.some((f) => !f.type || f.type === '-') === true) {
      errors.push('All funds must have a type.');
    } else if (
      values.fundEntries?.some((f) => f.color === undefined || f.color < 0) ===
      true
    ) {
      errors.push('All funds must have a type.');
    }
    if (values.fundEntries?.some((f) => !f.name) === true) {
      errors.push('All funds must have a name.');
    }
    if (values.fundEntries?.some((f) => !f.overview) === true) {
      errors.push('All funds must have an overview.');
    }
    const overviewLength =
      values.fundEntries?.reduce((a, b) => {
        return a + (b.overview?.length ?? 0);
      }, 0) ?? 0;

    if (overviewLength > maxCumulativeOverview) {
      errors.push(
        `Cumulative overview length cannot exceed ${maxCumulativeOverview} characters.`,
      );
    }
    return errors.length > 0 ? errors : false;
  };

  const saveSatellite = () => {
    snackbar.close();
    const validationErrors = validateSatellite();
    if (validationErrors) {
      let message = 'Fix the following errors before saving:';
      validationErrors.forEach((err) => {
        message += `\n - ${err}`;
      });
      snackbar.show({ message, multiline: true });
      return;
    }
    confirmModal(dialog, {
      title: 'Saving satellite',
      content: <>Are you sure you want to save changes?</>,
      confirmLabel: 'Save',
      onConfirm: () => {
        if (!id) return;
        const parsedSatellite = parseToUpdateInput(values);
        updateSatellite(id, parsedSatellite).then((res) => {
          if (res !== 'Success') {
            // eslint-disable-next-line no-alert
            return window.alert('Satellite update error');
          }
          return setHasChanges(false);
        });
      },
    });
  };

  return (
    <FullPage
      title={`Satellites${satelliteName}`}
      backTo={`${Routes.Factsheets}${
        currentTab ? `?${PREVIOUS_TAB_QUERY_PARAM}=${currentTab}` : ''
      }`}
      headerGradient
      endActions={<Header />}
    >
      {loading ? (
        <FullLoading />
      ) : (
        <Wrapper>
          <Prompt
            when={hasChanges}
            message="You have unsaved changes, are you sure you want to leave?"
          />
          <div className={classes.top}>
            <Button
              variant="outlined"
              className={`${classes.pageButton}${
                page === 1 ? ' selected' : ''
              }`}
              onClick={() => setPage(1)}
            >
              1st Page
            </Button>
            <Button
              variant="outlined"
              className={`${classes.pageButton}${
                page === 2 ? ' selected' : ''
              }`}
              onClick={() => setPage(2)}
            >
              2nd Page
            </Button>
            <Button
              color="primary"
              onClick={saveSatellite}
              className={classes.saveButton}
            >
              Save
            </Button>
          </div>
          {page === 1 ? (
            <FirstPage values={values} dispatch={dispatchWrap} />
          ) : (
            <SecondPage values={values} dispatch={dispatchWrap} />
          )}
        </Wrapper>
      )}
    </FullPage>
  );
};
