import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { getToken } from 'src/auth';
import { Satelite } from 'src/components/pages/Factsheets/SateliteList/Satelite';
import { SATELLITE_LAMBDA_URL } from './lambda-routes';
import { QueryStatus, RestQuery } from './types';

let satellites: Satelite[] | null = null;

export async function getSatellites(refresh?: boolean, getHidden?: boolean) {
  if (satellites && !refresh) return satellites;
  const options = {
    headers: {
      Authorization: await getToken(),
    },
  };

  const response = await axios
    .get(`${SATELLITE_LAMBDA_URL}/satelite`, options)
    .catch((error) => {
      throw new Error(error.response?.data?.error ?? 'Error');
    });

  const res = (response.data.data as Satelite[]).filter((s) => {
    return getHidden || !s.hidden;
  });
  satellites = res;
  return res;
}

export const useSatellitesQuery = (
  getHidden?: boolean,
): RestQuery<Satelite[]> => {
  const [data, setData] = useState<Satelite[] | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [isPending, setIsPending] = useState(false);

  const fetchData = useCallback(async () => {
    setIsPending(true);
    try {
      const res = await getSatellites(true, getHidden);
      setData(res);
      setError(null);
      setIsPending(false);
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsPending(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (error) {
    return {
      status: QueryStatus.Error,
      data: undefined,
      error,
      reFetch: fetchData,
    };
  }
  if (isPending) {
    return {
      status: QueryStatus.Pending,
      reFetch: fetchData,
      data: undefined,
    };
  }

  return {
    status: QueryStatus.Success,
    data: data ?? [],
    reFetch: fetchData,
  };
};
