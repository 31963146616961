export const formatDate = (
  date: string | number,
  customFormat = false,
  noTime = false,
  formatOptionsOverrides?: Intl.DateTimeFormatOptions,
  locales = 'en-GB',
) => {
  try {
    const dt = new Date(date);

    const diffInDays = Math.round(
      (new Date(Date.now()).setHours(0, 0, 0, 0) -
        new Date(dt).setHours(0, 0, 0, 0)) /
        60 /
        60 /
        24 /
        1000,
    );

    let formattedDate = new Intl.DateTimeFormat(locales, {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      ...(noTime ? {} : { hour: 'numeric', minute: 'numeric' }),
      ...formatOptionsOverrides,
    }).format(dt);

    if (customFormat && diffInDays < 7) {
      let datePart;
      switch (diffInDays) {
        case 0:
          datePart = 'Today';
          break;
        case 1:
          datePart = 'Yesterday';
          break;
        default:
          datePart = `${diffInDays}\xa0days\xa0ago`;
      }
      formattedDate = `${datePart},\xa0${formattedDate.slice(-5)}`;
    }

    return formattedDate;
  } catch {
    return '';
  }
};

export const shortDate = (date?: Date | null) => {
  return formatDate(
    date?.getTime() ?? 0,
    false,
    true,
    {
      month: 'short',
      day: undefined,
    },
    'en-US',
  );
};
